import { createStore } from 'vuex';

export default createStore({
  state: {
    showDialog: false
  },
  mutations: {
    setShowDialog(state, value) {
      state.showDialog = value;
    }
  },
  actions: {
    triggerDialog({ commit }) {
      commit('setShowDialog', true);
    }
  },
  modules: {}
});
