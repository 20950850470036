// messagingConfig.js
import { initializeApp } from "firebase/app";
import firebaseConfig from "../firebaseConfig";
import { getAuth } from "firebase/auth"; // pour obtenir le userId de l'utilisateur connecté
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging"; // onMessage retiré
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';


const firebaseApp = initializeApp(firebaseConfig);

const messaging = getMessaging(firebaseApp);
const db = getFirestore(firebaseApp);

// Fonction pour demander l'autorisation et récupérer le token
export const requestPermission = async () => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: "BIuEKQjNhtElAy6AZbTMm6V2JqtPN2C8MG4zWKv8m0IBGRzPTA76U78_r-vG9BbIHJs_AvTqERVj8aMe2I2qdq8" });
        if (currentToken) {
            console.log("Token reçu :", currentToken);
            
            // Obtenir le userId de l'utilisateur connecté
            const auth = getAuth();
            const user = auth.currentUser;

            if (user) {
                const userId = user.uid;
                // Stocker le token dans Firestore, dans le document utilisateur
                await setDoc(doc(db, "users", userId), { fcmToken: currentToken }, { merge: true });
            } else {
                console.log("Aucun utilisateur connecté.");
            }
        } else {
            console.log("Pas de token, autorisation non accordée.");
        }
    } catch (error) {
        console.error("Erreur lors de la récupération du token :", error);
    }
};

if (Capacitor.getPlatform() !== 'web') {
    PushNotifications.addListener('registration', (token) => {
        console.log('Token d\'inscription :', token.value);
    });

    PushNotifications.addListener('registrationError', (error) => {
        console.error('Erreur lors de l\'inscription :', error);
    });

    PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.log('Notification reçue :', notification);
    });

    PushNotifications.addListener('pushNotificationActionPerformed', (action) => {
        console.log('Action de notification effectuée :', action);
    });
}


export { messaging };

