import { createApp } from 'vue';
import App from './App.vue'
// import Vuetify from './vuetify' // Assurez-vous que le chemin est correct
import { IonicVue } from '@ionic/vue';
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import '@ionic/core/css/ionic.bundle.css';

import '@ionic/vue/css/core.css';

import store from './store';

import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

// Vérifiez si l'application est exécutée sur une plateforme native (iOS ou Android)
if (Capacitor.isNativePlatform()) {
  StatusBar.setBackgroundColor({ color: '#ffffff' }); // Remplacez #ffffff par la couleur souhaitée
  StatusBar.setStyle({ style: Style.Light }); // Utilisez Style.Dark ou Style.Light en fonction du contraste souhaité
}


// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives';

// import { AdMob } from '@capacitor-community/admob';
// import { Capacitor } from '@capacitor/core';

// Initialisation d'AdMob
// AdMob.initialize({
//   requestTrackingAuthorization: true,  // Demande d'autorisation de suivi sur iOS
//   testingDevices: ['RF8W102ZZXY'],  // Ajoutez ici les ID des appareils de test si nécessaire
//   initializeForTesting: true,  // Activez le mode test si nécessaire
// });


const vuetify = createVuetify({
  components,
  directives,
   theme: {
    defaultTheme: 'light',
  },
})



import router from "./router";

import VueGtag from 'vue-gtag-next';
// Importez les fonctions dont vous avez besoin depuis le SDK Firebase
import { initializeApp } from "firebase/app";

import { requestPermission } from "./messagingConfig"; // Importer la fonction pour demander le token


import './registerServiceWorker';

import "./messagingConfig";

import firebaseConfig from "../firebaseConfig";


// Initialiser Firebase avec les informations de configuration
initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// console.log(analytics);

// Initialisation de l'application Vue avec Ionic
// Créer l'application Vue
const vueApp = createApp(App);

// Utiliser le router dans l'application Vue
vueApp.use(router);

vueApp.use(VueGtag, {
  property: {
    id: 'G-MTH7VM2WNG'
  }
});

// Enregistrement du service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker enregistré avec succès:', registration);
    })
    .catch((error) => {
      console.error('Échec de l\'enregistrement du Service Worker:', error);
    });
}

// Monter l'application à l'élément avec l'id 'app'
vueApp.use(vuetify).use(IonicVue).use(store).mount('#app');

// Demander l'autorisation pour les notifications lorsque l'application démarre
requestPermission();

