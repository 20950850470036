
// Configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDeSD6voGNPzaWU7513-neW3MxzM3CI7qg",
  authDomain: "annli-ese.firebaseapp.com",
  projectId: "annli-ese",
  storageBucket: "annli-ese.appspot.com",
  messagingSenderId: "514396492015",
  appId: "1:514396492015:web:31073123af4ba0756b8b1a",
  measurementId: "G-MTH7VM2WNG"
};



export default firebaseConfig;