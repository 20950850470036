import { createRouter, createWebHistory } from 'vue-router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import Cookies from 'js-cookie';
import store from './store';

// Créer le router
const router = createRouter({
  history: createWebHistory(),
  routes: [
    // LES ROUTES DISPONIBLES QUAND NON CONNECTER
 
    { path: "/", component: () => import("./pages/UnConnected-Page.vue") },

    { path: "/UnconnectedNewDemo", component: () => import("./pages/UnConnected-Page-New-Demo.vue") },

    { path: "/RegisterPage", component: () => import("./pages/Register-Page.vue") },
    
    { path: "/ForgotPasswordPage", component: () => import("./pages/Forgot-Password-Page.vue") },

    { path: "/HelpPage", component: () => import("./pages/To-Help-Page.vue") },
    { path: "/NotificationPage", component: () => import("./pages/Accept-Notification-Page.vue") },

    //LES ROUTES DISPONIBLES QUAND CONNECTER

    { path: "/HomePage", name: 'homePage', component: () => import("./pages/Home-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },
     
      { path: "/BookPropositionPage/:id", name: 'proposition-page', component: () => import("./pages/Book-Proposition-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },
        
    { path: "/AdminPage", name: 'adminPage', component: () => import("./pages/Admin-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },
        
    
    {
      path: "/CommentairesLecteursReviseursPage", name: 'commentaires-lecteurs-reviseurs-Page',
      component: () => import("./pages/Commentaires-Lecteurs-Reviseurs-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },
        

     {
      path: "/CommentairesLecteursReviseursForAdminPage", name: 'commentaires-lecteurs-reviseurs-for-admin-Page',
      component: () => import("./pages/Commentaires-Lecteurs-Reviseurs-For-Admin-Page.vue"),
    meta: {
          requiresAuth: true,
        }, },


      { path: "/PolitiqueConfidentialitePage", name: 'politique-page', component: () => import("./pages/Politique-Confidentialite-Page.vue"),
    meta: {
          requiresAuth: true,
        }, },

  { path: "/PolitiqueUserPage", name: 'politique-user-page', component: () => import("./pages/Politique-User-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },
        
    { path: "/PolitiqueUserConnectPage", name: 'politique-user-connect-page', component: () => import("./pages/Politique-User-Connect-Page.vue"),
    meta: {
          requiresAuth: true,
        }, },
        
          { path: "/ConditionUtilisationPage", name: 'condition-page', component: () => import("./pages/Condition-Utilisation-Page.vue"),
    meta: {
          requiresAuth: true,
        }, },

         { path: "/ConditionUserPage", name: 'condition-user-page', component: () => import("./pages/Condition-User-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },
        
    { path: "/ConditionUserConnectPage", name: 'condition-user-connect-page', component: () => import("./pages/Condition-User-Connect-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },


    
     { path: "/WhoAreWePage", name: 'who-are-we-page', component: () => import("./pages/Who-Are-We-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

     { path: "/WhoAreWeConnectPage", name: 'who-are-we-connect-page', component: () => import("./pages/Who-Are-We-Connect-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

      { path: "/WhoAreWeUnconnectPage", name: 'who-are-we-unconnect-page', component: () => import("./pages/Who-Are-We-Unconnect-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },
        
    
    { path: "/SearchPage", component: () => import("./pages/Search-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    
    
    },


     { path: "/UserSearchPage", name: 'user-search-page', component: () => import("./pages/User-Search-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    
    
    },


      { path: "/ResiliationPage", name: 'resiliation-page', component: () => import("./pages/Resiliation-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    
    
    },


    { path: "/LibraryPage", component: () => import("./pages/Library-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },
       
        { path: "/LibraryPageOffline", component: () => import("./pages/Library-Page-Offline.vue"),
    meta: {
          requiresAuth: false,
      },
    },

      { path: "/ContactPage", name: 'contact-page', component: () => import("./pages/Contact-Page.vue"),
    meta: {
          requiresAuth: false,
        }, },

    { path: "/PublishPage", name: 'publishPage', component: () => import("./pages/Publish-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },

     { path: "/PublishPageOffline", name: 'publishPageOffline', component: () => import("./pages/Publish-Page-Offline.vue"),
    meta: {
          requiresAuth: false,
      },
    },
        
   
     { path: "/MessageAbonnementPage", name:'message-abonnement-page', component: () => import("./pages/Message-Abonnement-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },


      { path: "/MessageAbonnementOfflinePage", component: () => import("./pages/Message-Abonnement-Offline-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },


         { path: "/NotificationsPage", name:'notifications-page', component: () => import("./pages/Notifications-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },
    
    {
      path: "/NotificationsPageOffline", name: 'notifications-page-offline',
      component: () => import("./pages/Notifications-Page-Offline.vue"),
    meta: {
          requiresAuth: false,
      },
    },

          { path: "/NotificationsFromProfilPage", name:'notifications-from-profil-page', component: () => import("./pages/Notifications-From-Profil-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

          { path: "/NotificationsFromLibraryPage", component: () => import("./pages/Notifications-From-Library-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },


          { path: "/NotificationsFromAbonnementPage", component: () => import("./pages/Notifications-From-Abonnement-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

         { path: "/NotificationsFromSearchPage", component: () => import("./pages/Notifications-From-Search-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },


       { path: "/ProfilPage", name: 'profil-page', component: () => import("./pages/Profil-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

      { path: "/RatingAppPage", name: 'rating-app-page', component: () => import("./pages/Rating-App-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

        { path: "/AbonnementToAnnLiPage", name: 'abonnement-to-annli-page', component: () => import("./pages/Abonnement-To-AnnLi-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

      { path: "/AbonnementToAnnLiProfilPage", name: 'abonnement-to-annli-profil-page', component: () => import("./pages/Abonnement-To-AnnLi-From-Profil-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

       { path: "/ParametresPage", component: () => import("./pages/Parametres-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },
        { path: "/FormNewPublishStoryPage", component: () => import("./pages/Form-New-Publish-Story-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

    { path: "/FormNewPublishPoemPage", component: () => import("./pages/Form-New-Publish-Poem-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

     { path: "/AllCardSubscribeWritersPage", component: () => import("./pages/All-Card-Subscribe-Writers-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

     { path: "/DisplayRankingBookColRowPage", component: () => import("./pages/Display-Ranking-Book-ColRow-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

     { path: "/DisplayNewBookColRowPage", component: () => import("./pages/Display-New-Book-ColRow-Page.vue"),
    meta: {
          requiresAuth: false,
      },
    },

    { path: "/DisplayIsReadingColRowPage", component: () => import("./pages/Display-Is-Reading-ColRow-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

    
    { path: "/DisplayIsReadingColRowPageFromLibrary", component: () => import("./pages/Display-Is-Reading-ColRow-From-Library-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

      { path: "/DisplaySavedBookColRowPageFromLibrary", component: () => import("./pages/Display-Saved-Book-ColRow-Page-From-Library.vue"),
    meta: {
          requiresAuth: true,
      },
    },

     { path: "/DisplaySavedBookColRowPage", component: () => import("./pages/Display-Saved-Book-ColRow-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

    { path: "/FormChaptersPublish/:id", name: 'form-chapters-publish', component: () => import("./components/connected-components/Form-Chapters-Publish.vue"),
    meta: {
          requiresAuth: true,
      },
    },

    { path: "/FormChaptersPublishBis/:id", name: 'form-chapters-publish-bis', component: () => import("./components/connected-components/Form-Chapters-Publish-Bis.vue"),
    meta: {
          requiresAuth: true,
      },
    },

     { path: "/ModifyBookInfoGeneral/:id", name: 'modify-book-info-general', component: () => import("./components/connected-components/Modify-Book-Info-General.vue"),
    meta: {
          requiresAuth: true,
      },
    },

      { path: "/ModifyBookInfoGeneralBis/:id", name: 'modify-book-info-general-bis', component: () => import("./components/connected-components/Modify-Book-Info-General-Bis.vue"),
    meta: {
          requiresAuth: true,
      },
    },

       { path: "/ModifyPages/:id", name: 'modify-pages', component: () => import("./components/connected-components/Modify-Pages.vue"),
    meta: {
          requiresAuth: true,
      },
    },


     { path: "/ModifyPagesUsers/:id", name: 'modify-pages-users', component: () => import("./components/connected-components/Modify-Pages-Users.vue"),
    meta: {
          requiresAuth: true,
      },
    },


     { path: "/CreateModifyPages/:id", name: 'create-modify-pages', component: () => import("./components/connected-components/Create-Modify-Pages.vue"),
    meta: {
          requiresAuth: true,
      },
    },

       { path: "/MenuTranslators/:id", name: 'menu-translators', component: () => import("./components/connected-components/Menu-Translators.vue"),
    meta: {
          requiresAuth: true,
      },
    },


       { path: "/AccessToCreatePage/:id", name: 'access-to-create-page', component: () => import("./components/connected-components/Access-To-Create-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

     { path: "/MyCorrectionsPage/:id", name: 'my-corrections-page', component: () => import("./components/connected-components/My-Corrections-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

    {
      path: "/CorrectionsOfUsers/:id", name: 'corrections-of-users',
      component: () => import("./components/connected-components/Corrections-Of-Users.vue"),
    meta: {
          requiresAuth: true,
      },
    },


     { path: "/DeletePages/:id", name: 'delete-pages', component: () => import("./components/connected-components/Delete-Pages.vue"),
    meta: {
          requiresAuth: true,
      },
    },


    
     { path: "/DeletePagesUsers/:id", name: 'delete-pages-users', component: () => import("./components/connected-components/Delete-Pages-Users.vue"),
    meta: {
          requiresAuth: true,
      },
    },



      { path: "/ModifyEachPage/:id/:chapterId", name: 'modify-each-page', component: () => import("./components/connected-components/Modify-Each-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },

    {
      path: "/ModifyEachPageWriters/:id/:chapterId", name: 'modify-each-page-writers',
      component: () => import("./components/connected-components/Modify-Each-Page-Writers.vue"),
    meta: {
          requiresAuth: true,
      },
    },


     {
      path: "/DeleteEachField/:id/:chapterId", name: 'delete-each-field',
      component: () => import("./components/connected-components/Delete-Each-Field.vue"),
    meta: {
          requiresAuth: true,
      },
    },

     { path: "/SendNotifications/:id", name: 'send-notifications', component: () => import("./components/connected-components/Send-Notifications.vue"),
    meta: {
          requiresAuth: true,
      },
    },

      { path: "/AllNotifications/:id", name: 'all-notifications', component: () => import("./components/connected-components/All-Notifications.vue"),
    meta: {
          requiresAuth: true,
      },
    },

     { path: "/ParametersBook/:id", name: 'parameters-book', component: () => import("./components/connected-components/Parameters-Book.vue"),
    meta: {
          requiresAuth: true,
      },
    },

    {
      path: '/messageAbonnementVideoDetails/:id', name: 'message-abonnement-video-details', component: () => import("./components/connected-components/Message-Abonnement-Video-Details.vue"),
    meta: {
          requiresAuth: true,
      },
    },

   

    {
      path: '/messageAbonnementImageDetails/:id', name: 'message-abonnement-image-details', component: () => import("./components/connected-components/Message-Abonnement-Image-Details.vue"),
    meta: {
          requiresAuth: true,
      },
    },

     {
      path: '/messageAbonnementDetails/:id', name: 'message-abonnement-details', component: () => import("./components/connected-components/Message-Abonnement-Details.vue"),
    meta: {
          requiresAuth: true,
      },
    },

      {
      path: '/notificationDetails/:id', name: 'notification-details', component: () => import("./components/connected-components/Notification-Details.vue"),
    meta: {
          requiresAuth: true,
      },
    },


      {
      path: '/CommentDetails/:id', name: 'comment-details', component: () => import("./components/connected-components/Comment-Details.vue"),
    meta: {
          requiresAuth: true,
      },
    },


    
      {
      path: '/CommentPage/:id', name: 'comment-page', component: () => import("./components/connected-components/Comment-Page.vue"),
    meta: {
          requiresAuth: true,
      },
    },


      {
      path: '/detailsOfNotif/:id', name: 'details-of-notif', component: () => import("./components/connected-components/Details-Of-Notif.vue"),
    meta: {
          requiresAuth: true,
      },
    },
  
     
      {
        path: '/bookDetails/:id', name: 'book-details',  props: true, 
        component: () => import("./components/connected-components/Book-Details.vue"),
    meta: {
          requiresAuth: false,
      },
    },


    
      {
        path: '/thankForReading/:id', name: 'thank-for-reading',  props: true, 
        component: () => import("./components/connected-components/Thank-For-Reading.vue"),
    meta: {
          requiresAuth: false,
      },
    },


      {

     path: '/bookContent/:id', name: 'book-content',  props: true, 
        component: () => import("./components/connected-components/Book-Content.vue"),
    meta: {
          requiresAuth: false,
      },
    },


      {
        path: '/bookDetailsShare/:id', name: 'book-details-share',  props: true, 
        component: () => import("./components/connected-components/Book-Details-Share.vue"),
    meta: {
          requiresAuth: false,
      },
    },


      {

     path: '/bookContentShare/:id', name: 'book-content-share',  props: true, 
        component: () => import("./components/connected-components/Book-Content-Share.vue"),
    meta: {
          requiresAuth: false,
      },
    },


        {
        path: '/bookDetailsFromParametersBook/:id', name: 'book-details-from-parameters-book',  props: true, 
        component: () => import("./components/connected-components/Book-Details-From-Parameters-Book.vue"),
    meta: {
          requiresAuth: true,
      },
    },

 
        {

       path: '/userDetails/:id', name: 'user-details',  props: true, 
        component: () => import("./components/connected-components/User-Details.vue"),
    meta: {
          requiresAuth: false,
      },
    },


    
        {

       path: '/WriterDetails/:id', name: 'writer-details',  props: true, 
        component: () => import("./components/connected-components/Writer-Details.vue"),
    meta: {
          requiresAuth: false,
      },
    },

        {

       path: '/EachUserDetails/:id', name: 'each-user-details',  props: true, 
        component: () => import("./components/connected-components/Each-User-Details.vue"),
    meta: {
          requiresAuth: true,
      },
    },


      
       {
      path: '/bookChapters/:id', name: 'book-chapters', component: () => import("./components/connected-components/Book-Chapters.vue"),
    meta: {
          requiresAuth: false,
      },
    },


        {
      path: '/bookChaptersSpecific/:id/:chapterId', name: 'book-chapters-specific', component: () => import("./components/connected-components/Book-Chapters-Specific.vue"),
    meta: {
          requiresAuth: false,
      },
    },

      {
      path: '/CommentFour/:id/:chapterId', name: 'comment-four', component: () => import("./components/connected-components/Comment-Four.vue"),
    meta: {
          requiresAuth: false,
      },
    },

       {
      path: '/bookChaptersSomePages/:id', name: 'book-chapters-some-pages', component: () => import("./components/connected-components/Book-Chapters-Some-Pages.vue"),
    meta: {
          requiresAuth: false,
      },
    },

    
          

             { path: "/ConfirmSubscriptionPage", name: 'confirm-subscription', component: () => import("./pages/Confirm-Subscription.vue"),
    meta: {
          requiresAuth: true,
      },
    },

  ],
});

// const getCurrentUser = () => {
//   return new Promise((resolve, reject) => {
//     const removeListener = onAuthStateChanged(
//       getAuth(),
//       (user) => {
//         removeListener();
//         // Stocker l'utilisateur dans un cookie avec une expiration
//         Cookies.set('user', JSON.stringify(user), { expires: 90 }); // Exemple : expire après 90 jours
//         resolve(user);
//         // console.log(Cookies);
//       },
//       reject
//     );
//   });
// }

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

// router.beforeEach(async (to, from, next) => {
//   const user = await getCurrentUser();

//   if (to.path === "/" && user) {
//     next("/HomePage");
//   } else if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (user) {
//       next();
//     } else {
//       alert("You don't have access!");
//       next("/");
//     }
//   } else {
//     next();
//   }
// });



// router.beforeEach(async (to, from, next) => {
//    // Utiliser la fonction getCurrentUser pour obtenir l'utilisateur
//    const user = await getCurrentUser();
//   // const storedUser = Cookies.get('user');
//   // const user = storedUser ? JSON.parse(storedUser) : null;

//   if (to.path === "/" && user) {
//     // Si l'utilisateur est connecté, rediriger vers la page ConnectedPage
//     next("/HomePage");
//     // console.log("okk");
//   } else if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (user) {
//       // L'utilisateur est connecté, autoriser l'accès
//       next();
//     } else {
//       alert("Ou pa gen aksè! Enskri oubyen konekte pou w gen aksè ak tout kontni sa yo");
//       next("/"); // Rediriger vers la page d'accueil si l'utilisateur n'est pas connecté
//     }
//   } else {
//     next(); // Laisser passer les routes qui ne nécessitent pas d'authentification
//   }
// });


router.beforeEach(async (to, from, next) => {
  const user = await getCurrentUser();

  if (to.path === "/" && user) {
    next("/HomePage");
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (user) {
      next();
    } else {
      store.commit('setShowDialog', true);
      next("/");
    }
  } else {
    next();
  }
});

export default router;
