<template>
    <v-app class="bottom-safe-area">
        <router-view v-slot="{ Component }">
            <transition v-if="isTransitionPage" name="slide" mode="out-in">
                <component :is="Component" />
            </transition>
            <component v-else :is="Component" />
        </router-view>
        <DialogWarning :dialog="showDialog" @update:dialog="setShowDialog" />
    </v-app>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DialogWarning from './components/connected-components/Dialog-Warning.vue';

export default {
    components: {
        DialogWarning
    },
    computed: {
        isTransitionPage() {
            // Vérifiez si la route active est "bo0k-chapters" ou "book-chapters-specific"
            return this.$route.name === 'book-chapters' || this.$route.name === 'book-chapters-specific';
        },
        ...mapState(['showDialog'])
    },
    methods: {
        ...mapMutations(['setShowDialog'])
    }
};
</script>

<style>
.bottom-safe-area {
    padding-bottom: env(safe-area-inset-bottom);
}
.slide-enter-active, .slide-leave-active {
    transition: transform 0.6s ease;
}
.slide-enter {
transform: translateX(100%);
}
.slide-leave-to {
    transform: translateX(-100%);
}
</style>
